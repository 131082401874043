<script setup lang="ts">
import { useLocalizationStore } from '~/store/localization/localization'

const localizationStore = useLocalizationStore()
const { locale } = useI18n()

onMounted(() => {
  // localizationStore.resetState();
  locale.value = localizationStore.getSelectedLanguage().literal
})
</script>

<template>
  <v-app>
    <slot></slot>
  </v-app>
</template>
